.section {
  margin-bottom: 1rem;
  padding-top: 1rem;
}

.title {
  margin-bottom: 1rem;
  text-align: center;
  font-size: 2rem;
  text-transform: uppercase;
}

.horizontalRule {
  height: 5px;
  background-color: #5be584;
  border: none;
  margin-bottom: 1rem;
}

.sectionTitle {
  margin-bottom: 0.5rem;
  border-left: 5px solid #5be584;
  padding-left: 1rem;
}

.listItemIcon {
  width: 0.5rem;
  height: 0.5rem;
}

.content {
  margin-bottom: 0.5rem;
}

.container {
  max-width: calc(100% - 400px);
  margin-left: 400px;

  @media (max-width: 1200px) {
    max-width: calc(100% - 340px);
    margin-left: 340px;
  }

  @media (max-width: 900px) {
    max-width: 100%;
    margin-left: 0;
  }
}

.drawer > div {
  min-width: 400px;
  box-shadow: 0 0 1rem grey;

  @media (max-width: 1200px) {
    min-width: 180px;
  }
}

.drawer ul {
  padding: 0;
}

.quickAccess {
  font-size: 2rem;
  text-align: center;
  text-transform: uppercase;
  padding-top: 1rem;
  padding-bottom: 1rem;
  background-color: #00ab55;
  color: white;
}

.quickAccessList {
  border-left: 5px solid #5be584;
  cursor: pointer;

  @media (max-width: 1200px) {
    max-width: 340px;
  }
}
